@import url("./styles/base.css");
@import url("./styles/queries.css");
body{

}

.wrapper {
    overflow-y: scroll;
    display: flex;
    margin: 0;

    height: 100vh;
    -webkit-box-orient: horizontal;
    -o-box-orient: horizontal;

    flex-direction: column;
}

.content {
    position: relative;
    /*top: var(--topbar-height);*/
    left: 0;

    flex: 1;
    order: 1;
}

.logoutButton {
    position: absolute;
    top: 10px;
    right: 10px;
}

.profile-container {
    width: 100%;
    float: left;
    padding: 15px;
}

.form-horizontal .form-group {
    margin: 0px;
    margin-top: 5px;
}

/* Fixes react-toolbox tab outlines */
section:focus {
    outline: none !important;
}

/* http://stackoverflow.com/a/18477316/5656782 */
@media only screen and (max-width: 320px) {
    float: none;
}